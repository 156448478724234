<template>
  <Error v-if="errors.length > 0" :errors="errors" />
  <router-view v-else />
</template>

<script setup lang="ts">
import Error from "$commons/components/web/Error.vue"
import ErrorUnauthorized from "$commons/lib/ErrorUnauthorized"
import { onErrorCaptured, ref } from "vue"
import { useRouter } from "vue-router"
import * as presets from "$commons/presets"
import { usePageStore } from "$commons/components/page"
import { useSessionStore } from "./store/session"

presets.install()

const router = useRouter()
const errors = ref([] as Error[])
const page = usePageStore()
const session = useSessionStore()

onErrorCaptured(err => {
  if (err instanceof ErrorUnauthorized) {
    session.clear()
    page.sweepGoto("/agents/login")
    return false
  }

  console.error(err)

  errors.value.push(err)
  return false
})

router.afterEach(() => {
  errors.value = []
})
</script>
